<template>
  <div :class="classObj" class="layout">
    <lendHyoerspotHeader/>
    <router-view id="main" class="layout-content"/>
  </div>
</template>

<script>

import lendHyoerspotHeader from "@/components/lendHyoerspotHeader"

export default {
  name: "layout",
  components: {
    lendHyoerspotHeader,
  },
  data() {
    return {
      showPrice: true,
      dialogVisible: false,
      isCollapse: true,
    }
  },
  watch: {
    isShowConnect() {
      this.dialogVisible = this.$store.state.app.isShowConnect
    }
  },
  computed: {
    isShowConnect() {
      return this.$store.state.app.isShowConnect
    },
    classObj() {
      return {
      }
    }
  },
  created() {

  },
  methods: {
    chooseChain(chain){
      this.isShowSelect = false
      this.curChain = chain
    },
    onClose() {
      this.$store.commit("app/SET_SHOWCONNECT")
    },
    async registerWeb3() {

    },
    async getWallet() {
      if (typeof window.ethereum == 'undefined') {
        this.$message.error("down metamask")
      }
      window.ethereum.request({method: 'eth_requestAccounts'});
      this.registerWeb3()

    },
  }
}
</script>

<style lang="scss">

.layout {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  background: linear-gradient(180deg, #56357E 0%, #0E0025 100%);
  flex-direction: column;

}


</style>
