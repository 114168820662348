<template>
  <div class="rain-header">
    <div class="header-box" :style="dialogVisible ? 'z-index: 1000' :'z-index:10'">
      <div class="header-left">
        <img src="../assets/img/logo.png" alt="">
      </div>
      <div class="nav-list">
        <div class="nav-item">
          <a href="http://aceai.tech" target="_blank">
            <img src="../assets/img/icon1.png" alt="">
          </a>
        </div>
        <div class="nav-item">
          <img ref="copyBtn" :data-clipboard-text="copyContent" src="../assets/img/icon2.png" @click="share" alt="">
        </div>
      </div>
      <div class="header-right">
        <ConnectWallet class="connect-wallet"></ConnectWallet>
      </div>
    </div>
    <div class="item-nav-header">

    </div>
    <div class="message-box">
      <div class="item" v-for="item in message" :key="item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet";
import {mapGetters} from "vuex";
import ClipboardJS from 'clipboard';

export default {
  name: "lendHyoerspotHeader",
  components: {
    ConnectWallet,
  },
  data() {
    return {
      clipboard:{},
      message: [],
      dialogVisible: false,
      copyContent: window.location.host + '/?ref=' + this.$store.state.app.account
    }
  },
  methods: {
    share() {
      if (!this.$store.state.app.account) {
        this.message.push("Please connect wallet ")
        return
      }
      // const link = window.location.host + "/?ref="+this.$store.state.app.account;

      this.clipboard.on('success', () => {
        this.message.push("The link has been copied ")
      });

      this.clipboard.on('error', () => {
        this.message.push("Copy err ")
      });


      setTimeout(() => {
        this.message.pop()
      }, 3000)
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar", !this.sidebar)
    },
    chooseAction() {
    }
  },

  created() {

  },
  mounted() {
    const clipboard = new ClipboardJS(this.$refs.copyBtn);
    this.clipboard = clipboard
  },
  watch: {
    account() {
      this.copyContent = window.location.host + '/?ref=' + this.$store.state.app.account
    }
  },
  computed: {
    ...mapGetters([
      'isConnected',
      'account'
    ]),
    navArr() {
      return this.$store.state.app.navArr
    },
    account() {
      return this.$store.state.app.account
    },
    sidebar() {
      return this.$store.state.app.isCollapse
    },
    userId() {
      // return this.$store.state.lendHyoerspot.userId
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.message-box {
  position: fixed;
  left: 10vw;
  top: 30vh;
  z-index: 10;

  .item {
    margin-top: 20px;
    width: 80vw;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.8);
  }
}

.rain-header {
  position: relative;
  z-index: 1000;
  padding: 0 0px 0 20px;
  background-size: 100% 100%;

  .item-nav-header {
    width: 100%;
  }

  .header-box {
    overflow: hidden;
    height: 60px;
    display: flex;
    padding-right: 2%;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    z-index: 10;
    justify-content: space-between;
    background-size: cover;
    position: relative;

    .header-left {
      img {
        width: 60px;
      }
    }

    .nav-list {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      font-size: 16px;

      .nav-item {
        img {
          width: 60px;
          margin-top: 20px;
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      div {
        margin-left: 20px;
      }
    }
  }

  .connect-wallet {
    ::v-deep .el-button {
      span {
        font-size: 10px;
        padding: 8px 0;
      }
    }
  }

}

</style>
